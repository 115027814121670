import loginRequest from '@/utils/loginRequest'
import Qs from 'qs'



export function detail(params) {
	return loginRequest({
    url: '/frontend/website/trade-system/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}

export function ad(params) {
	return loginRequest({
    url: '/frontend/website/ad/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}


export function adright(params) {
	return loginRequest({
    url: '/frontend/website/ad_right/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}

export function toutiaoad(params) {
	return loginRequest({
    url: '/frontend/website/shouyead/detail',
		method: 'post',
		data:Qs.stringify(params)
	})
}



